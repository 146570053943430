import React, {Component} from 'react';
import Chart from "chart.js";

export default class Pie extends Component {

    state = {
        chart: {},
    };

    componentDidMount() {
        const node = this.node;
        let chart = new Chart(node, {
            type: 'doughnut',
            data: {
                labels: ["On time", "In transit", "Late"],
                datasets: [{
                    data: this.props.data,
                    backgroundColor: [
                        '#38d942',
                        '#ffe14e',
                        '#ff4661',
                    ],
                    hoverBackgroundColor: [
                        '#38d942',
                        '#ffe14e',
                        '#ff4661',
                    ]
                }]
            },
            options: {
                onClick: this.handleClick,
            }
        });
        this.setState({chart})
    }

    handleClick = e => {
        if (this.state.chart.getElementsAtEvent(e)[0] !== undefined) {

            let statuses = {"In transit": "Open", "Late": "Late", "Delivered on time": "All"};
            let obj = {
                target: {
                    name: "open", value: statuses[this.state.chart.getElementsAtEvent(e)[0]._model.label]
                }
            };
            this.props.handleChange(obj);
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        let that = this;
        that.state.chart.data.datasets[0].data = nextProps.data;
        this.state.chart.update();
    }

    render() {
        return (
            <canvas ref={node => (this.node = node)}/>
        );
    }
}
