import React, {Component} from 'react';
import './App.css';
import Bookings from "./booking/Bookings";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Settings from "./settings/Settings";
import Login from "./login/Login";
import Navbar from "./navigation/Navbar";
import Help from "./documentation/Help";
import Test from "./settings/Test";
import resetUserPassword from "./settings/resetUserPassword";

export default class App extends Component {

    state = {loggedIn: false};

    setLoggedIn = () => {
        this.setState({loggedIn: !this.state.loggedIn})
    };

    render() {
        if (localStorage.getItem("user") === null) {
            localStorage.clear();
            return <Login setLoggedIn={this.setLoggedIn}/>

        }
            // if (!this.state.loggedIn) {
            //     return (
            //     )
        // }
        else {
            return (
                <Router>
                    <div style={{
                        backgroundColor: "#ecf4ff",
                        minHeight: "100vh",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <Navbar/>
                        <Switch>
                            <Route exact path={"/"} component={Bookings}/>
                            <Route path={"/settings"} component={Settings}/>
                            <Route path={"/help"} component={Help}/>
                            <Route path={"/test"} component={Test}/>
                            <Route path={"/resetUserPassword"} component={resetUserPassword}/>
                        </Switch>
                    </div>
                </Router>
            );
        }
    }
}