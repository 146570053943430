import React, { Component } from 'react';
import Pie from '../statistics/Pie';
import Filters from './Filters';
import Bar from '../statistics/Bar';
import Spinner from '../utils/Spinner';
import { ip, dev, devIp } from '../../package.json';
import POD from './POD';

export default class Bookings extends Component {
    /**
     * @param booking
     * @param booking.timeDeviation
     * @param booking.withinTransitTime
     * @param booking.ankDate
     * @param booking.afgDate
     * @param booking.aktAfgDate
     * @param booking.derRef
     * @param booking.acountry
     * @param booking.aname
     * @param booking.azip
     * @param booking.mcountry
     * @param booking.mname
     * @param booking.mzip
     * @param booking.activity
     * @param booking.ourDeviation
     * @param booking.customerDeviation
     */

    state = {
        customer: '0',
        country: 'All',
        aCountry: 'All',
        impExp: 'All',
        status: 'Open',
        endEta: null,
        startEta: null,
        countryList: [],
        aCountryList: [],
        bookings: [],
        pie: [],
        barStatistic: [],
        user: {},
        page: 1,
        pages: '',
        noOfResults: 10,
        filter: [],
        ip: dev ? devIp : ip,
    };

    async componentWillMount() {
        await this.setState({ user: localStorage.getItem('user') });
        this.fetchInitBookings();
    }

    fetchInitBookings = () => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.admin === true) {
            fetch(this.state.ip + 'initBookings?customerNo=0')
                .then(response => {
                    return response.json();
                })
                .then(bookings => {
                    this.setState({
                        bookings,
                    });
                })
                .then(() => {
                    this.doBarStat();
                    this.doPieStat();
                    this.setState({
                        page: 1,
                        pages: Math.ceil(this.state.bookings.length / 10),
                    });
                });
        } else {
            let customer = JSON.parse(localStorage.getItem('customer'));
            if (customer === null) customer = { customerNo: 129906 };
            fetch(this.state.ip + 'initBookings?customerNo=' + customer.customerNo)
                .then(response => {
                    return response.json();
                })
                .then(bookings => {
                    console.log(bookings);
                    this.setState({
                        bookings,
                        customer: customer.customerNo,
                    });
                })
                .then(() => {
                    this.doBarStat();

                    this.doPieStat();

                    this.setState({
                        page: 1,
                        pages: Math.ceil(this.state.bookings.length / 10),
                    });
                });
        }
    };

    doBarStat = () => {
        // Country filter
        let countryList = this.state.bookings.map(booking => booking.mcountry);
        countryList = countryList.filter(e => {
            return e != null;
        });
        let uniqueCountries = countryList.filter((v, i, a) => a.indexOf(v) === i);
        uniqueCountries = uniqueCountries.filter(e => {
            return e != null;
        });
        // Arr country filter
        let aCountryList = this.state.bookings.map(booking => booking.acountry);
        let aUniqueCountries = aCountryList.filter((v, i, a) => a.indexOf(v) === i);
        aUniqueCountries = aUniqueCountries.filter(e => {
            return e != null;
        });

        uniqueCountries.sort();
        aUniqueCountries.sort();

        const barStatistic = countryList.reduce((tally, country) => {
            tally[country] = (tally[country] || 0) + 1;
            return tally;
        }, {});

        this.setState({
            countryList: uniqueCountries,
            aCountryList: aUniqueCountries,
            barStatistic,
        });
    };

    doPieStat = () => {
        let ok = this.state.bookings.filter(booking => booking.ankDate !== null && booking.withinTransitTime).length;
        let inTransit = this.state.bookings.filter(booking => booking.ankDate === null && booking.withinTransitTime)
            .length;
        let timeDev = this.state.bookings.filter(booking => booking.timeDeviation === true).length;
        let pie = [ok, inTransit, timeDev];
        this.setState({ pie });
    };

    fetchFilteredBookings = e => {
        console.log(e);
        fetch(this.state.ip + 'filterBookings', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(e),
        })
            .then(response => {
                return response.json();
            })
            .then(bookings => {
                this.setState({
                    bookings,
                });
            })
            .then(() => {
                this.doBarStat();

                this.doPieStat();

                this.setState({
                    page: 1,
                    pages: Math.ceil(this.state.bookings.length / 10),
                });
            });
    };

    getColor = booking => {
        if (booking.timeDeviation === true || !booking.withinTransitTime) {
            return '#FF6C60';
        } else if (booking.ankDate === null && booking.withinTransitTime) {
            return '#FFD53F';
        } else if (booking.ankDate !== null && booking.withinTransitTime) {
            return '#69FF66';
        }
    };

    renderBookings = () => {
        let bookings = [...this.state.bookings];
        let sortedBookings = bookings.sort((a, b) => {
            // return new Date(b.afgDate) - new Date(a.afgDate);
            return (
                new Date(b.afgDate === undefined || b.afgDate === null ? b.date : b.afgDate) -
                new Date(a.afgDate === undefined || a.afgDate === null ? a.date : a.afgDate)
            );
        });
        return sortedBookings
            .splice((this.state.page - 1) * this.state.noOfResults, this.state.noOfResults)
            .map(booking => {
                // if (booking.afgDate === undefined || booking.afgDate === null) console.log(booking);

                let afgDateTemp =
                    booking.afgDate === undefined || booking.afgDate === null ? booking.date : booking.afgDate;

                let afgDate = new Date(afgDateTemp).toLocaleDateString('da-DK', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                });

                let aktAfgDate = new Date(booking.aktAfgDate).toLocaleDateString('da-DK', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                });
                let ankDate = new Date(booking.ankDate).toLocaleDateString('da-DK', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                });

                return (
                    <tr key={booking.bookingNo}>
                        {this.state.user.admin ? <td /> : null}
                        <td>{booking.bookingNo}</td>
                        <td>{booking.derRef}</td>
                        <td>{!booking.acountry ? '' : booking.acountry + ' - ' + booking.azip}</td>
                        <td>{!booking.mcountry ? '' : booking.mcountry + ' - ' + booking.mzip}</td>
                        <td>{booking.mname}</td>
                        <td>{afgDate === '1. jan. 1970' ? null : afgDate}</td>
                        <td>{aktAfgDate === '1. jan. 1970' ? null : aktAfgDate}</td>
                        <td style={{ backgroundColor: this.getColor(booking) }}>
                            {ankDate === '1. jan. 1970' ? null : ankDate}
                        </td>
                        {this.renderMoreTD(booking)}
                        <POD bookingNo={booking.bookingNo} />
                    </tr>
                );
            });
    };

    renderMoreTD = booking => {
        return (
            <td>
                {booking.activity ? (
                    <span style={{ fontWeight: 700 }}>
                        Express {booking.activity.substr(2, 3) + ' day delivery'}{' '}
                        <i className="fas fa-shipping-fast fa-lg" style={{ color: '#2b33ff' }} />
                    </span>
                ) : null}
                {booking.ourDeviation ? (
                    <span>
                        <span style={{ fontWeight: 700 }}> Jumbo deviation:</span> {booking.ourDeviation}
                    </span>
                ) : null}
                {booking.customerDeviation ? (
                    <span>
                        <span style={{ fontWeight: 700 }}>Customer deviation: </span>
                        {booking.customerDeviation}
                    </span>
                ) : null}
            </td>
        );
    };

    handleDate = e => {
        let obj = { [e.target.name]: new Date(e.target.value) };
        this.setState({ [e.target.name]: new Date(e.target.value) });
        let filter = [...this.state.filter];
        filter.push(obj);
        this.setState({ filter }, () => this.fetchFilteredBookings(this.state));
    };

    handleChange = e => {
        let obj = { [e.target.name]: e.target.value };
        this.setState({ [e.target.name]: e.target.value });
        let filter = [...this.state.filter];
        filter.push(obj);
        this.setState({ filter }, () => this.fetchFilteredBookings(this.state));
    };

    reset = () => {
        this.setState(
            {
                customer: '0',
                country: 'All',
                aCountry: 'All',
                impExp: 'All',
                status: 'Open',
                endEta: null,
                startEta: null,
                page: 1,
            },
            this.fetchInitBookings,
        );
    };

    renderFilters = () => {
        return (
            <Filters
                user={JSON.parse(localStorage.getItem('user'))}
                handleChange={this.handleChange}
                handleDate={this.handleDate}
                countryList={this.state.countryList}
                aCountryList={this.state.aCountryList}
                aCountry={this.state.aCountry}
                impExp={this.state.impExp}
                country={this.state.country}
                startEta={this.state.startEta}
                endEta={this.state.endEta}
                status={this.state.status}
                reset={this.reset}
            />
        );
    };

    render() {
        if (JSON.parse(localStorage.getItem('user')).username === undefined) {
            return <Spinner />;
        } else {
            return (
                <div style={{ margin: 15 }}>
                    {this.renderFilters()}
                    <div className={'columns'}>
                        <div className={'column is-8 table-container'}>
                            <table
                                className={'table is-hoverable is-fullwidth'}
                                style={{
                                    opacity: '0.8',
                                    borderRadius: '3px',
                                    boxShadow:
                                        '0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)',
                                }}>
                                <thead className={'thead'}>
                                    <tr>
                                        {this.state.user.admin ? <th>Jumbo Reference</th> : null}
                                        <th>Book No.</th>
                                        <th>Customer Reference</th>
                                        <th>Departure Country - Zip</th>
                                        <th>Arrival Country - Zip</th>
                                        <th>Recipient</th>
                                        <th>Departure date</th>
                                        <th>Actual departure date</th>
                                        <th>Arrival date</th>
                                        <th>Express/Deviations</th>
                                        <th>POD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderBookings()}
                                    <tr style={{ height: 75 }}>
                                        <td>
                                            <span>Items per page:</span>
                                        </td>
                                        <td>
                                            <div className={'select'}>
                                                <select
                                                    value={this.state.noOfResults}
                                                    onChange={e =>
                                                        this.setState({
                                                            noOfResults: e.target.value,
                                                            pages: Math.ceil(
                                                                this.state.bookings.length / e.target.value,
                                                            ),
                                                            page: 1,
                                                        })
                                                    }>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td />

                                        <td style={{ align: 'center', height: 75 }}>
                                            <span>
                                                {this.state.bookings.length > 0
                                                    ? this.state.page * this.state.noOfResults -
                                                      this.state.noOfResults +
                                                      1 +
                                                      ' '
                                                    : '0 '}
                                                -{' '}
                                                {this.state.noOfResults * this.state.page > this.state.bookings.length
                                                    ? this.state.bookings.length + ' '
                                                    : this.state.noOfResults * this.state.page + ' '}
                                                {'of ' + this.state.bookings.length}
                                            </span>
                                        </td>
                                        <td />
                                        <td style={{ width: 150 }}>
                                            <div>
                                                <h5>
                                                    <span onClick={() => this.setState({ page: 1 })}>
                                                        <i
                                                            className="fas fa-angle-double-left"
                                                            style={{ margin: 3, cursor: 'pointer' }}
                                                        />
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            this.state.page > 1
                                                                ? this.setState(prevState => ({
                                                                      page: prevState.page - 1,
                                                                  }))
                                                                : null
                                                        }>
                                                        <i
                                                            className="fas fa-angle-left "
                                                            style={{ margin: 3, cursor: 'pointer' }}
                                                        />
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            this.state.page < this.state.pages
                                                                ? this.setState(prevState => ({
                                                                      page: prevState.page + 1,
                                                                  }))
                                                                : null
                                                        }>
                                                        <i
                                                            className="fas fa-angle-right "
                                                            style={{ margin: 3, cursor: 'pointer' }}
                                                        />
                                                    </span>
                                                    <span onClick={() => this.setState({ page: this.state.pages })}>
                                                        <i
                                                            className="fas fa-angle-double-right "
                                                            style={{ margin: 3, cursor: 'pointer' }}
                                                        />
                                                    </span>
                                                </h5>
                                            </div>
                                        </td>
                                        <td />
                                        <td />
                                        <td />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={'column is-4'}>
                            <div className={'columns'}>
                                <div className={'column is-12'}>
                                    <div
                                        style={{
                                            width: '100%',
                                            boxShadow:
                                                '0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={'column'}>
                                <div
                                    style={{
                                        background: 'white',
                                        opacity: '0.8',
                                        height: '100%',
                                        width: '100%',
                                        borderRadius: '3px',
                                        boxShadow:
                                            '0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)',
                                    }}>
                                    <span style={{ fontSize: 24, padding: 5, fontWeight: 700 }}>Delivery on time</span>
                                    <Pie data={this.state.pie} handleChange={this.handleChange} />
                                </div>
                            </div>
                            <div className={'column'}>
                                <div
                                    style={{
                                        background: 'white',
                                        opacity: '0.8',
                                        height: '100%',
                                        width: '100%',
                                        borderRadius: '3px',
                                        boxShadow:
                                            '0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)',
                                    }}>
                                    <span
                                        style={{
                                            fontSize: 24,
                                            padding: 15,
                                            fontWeight: 700,
                                        }}>
                                        Top arrival countries
                                    </span>
                                    <Bar data={this.state.barStatistic} handleChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
