import React, {Component} from "react";
import {dev, devIp, ip} from "../../package.json";

export default class Customer extends Component {

    state = {
        name: this.props.customer.name,
        customerNo: this.props.customer.customerNo,
        leadTimes: this.props.customer.leadTimes,
        company: this.props.customer.company,
        uid: this.props.customer.uid,
        ip: dev ? devIp : ip
    };

    delete = () => {
        fetch(this.state.ip + "deleteCustomer?customerNo=" + this.state.customerNo)
            .then(() => {
                console.log("Deleted customer: " + this.state.customerNo)
            })
    };

    save = () => {
        let customer;
        if (this.state.leadTimes === undefined && this.state.uid !== undefined) {
            customer = {
                name: this.state.name,
                customerNo: parseInt(this.state.customerNo),
                company: parseInt(this.state.company),
                uid: this.state.uid,
            };
        } else if (this.state.uid !== undefined) {
            customer = {
                name: this.state.name,
                customerNo: parseInt(this.state.customerNo),
                company: parseInt(this.state.company),
                leadTimes: this.state.leadTimes,
                uid: this.state.uid,
            };
        } else if (this.state.leadTimes !== undefined) {
            customer = {
                name: this.state.name,
                customerNo: parseInt(this.state.customerNo),
                company: parseInt(this.state.company),
                leadTimes: this.state.leadTimes
            }
        } else {
            customer = {
                name: this.state.name,
                customerNo: parseInt(this.state.customerNo),
                company: parseInt(this.state.company),
            };
        }

        fetch(this.state.ip + "saveCustomer", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(customer)
        })
            .then(() => {
                console.log("Created customer: " + this.state.customerNo)
            })

    };

    addLeadTime = () => {
        let leadTimes;
        if (this.state.leadTimes !== undefined) {
            leadTimes = [...this.state.leadTimes];
        } else {
            leadTimes = []
        }
        leadTimes.push({});
        this.setState({leadTimes});
    };

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    render() {
        return (
            <div className={"columns"}>
                <div
                    style={{
                        background: "white",
                        opacity: "0.8",
                        height: "100%",
                        width: "100%",
                        borderRadius: "3px",
                        boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)",
                        padding: 15,
                        margin: 15
                    }}>
                    <h1 className={"is-size-3"}>{this.props.customer.name}</h1>
                    <div className={"columns"}>
                        <div className={"column is-2"}>
                            <label>Name</label>
                            <br/>
                            <input className={"input"} name="name" onChange={this.handleChange} type="text"
                                   value={this.state.name}/>
                        </div>
                        <div className={"column is-3"}>
                            <label>Customer No.</label>
                            <br/>
                            <input className={"input"} name="customerNo" onChange={this.handleChange} type="number"
                                   value={this.state.customerNo}/>
                        </div>
                        <div className={"column is-3"}>
                            <label>Customer Company</label>
                            <br/>
                            <input className={"input"} name="company" onChange={this.handleChange} type="number"
                                   value={this.state.company}/>
                        </div>
                    </div>
                    <div style={{padding: 10}}>
                        <button className={"button is-success"} style={{marginRight: 5}} onClick={this.save}>Save
                        </button>
                        <button className={"button is-danger"} onClick={this.delete}>Delete customer</button>
                    </div>
                </div>
            </div>
        )
    }
}