import React, {Component} from 'react';
import Users from "./Users";
import {Redirect, Route} from "react-router-dom";
import Menu from "../navigation/Menu";
import LeadTimes from "./LeadTimes";
import UniteamSettings from "./UniteamSettings";
import BookingSettings from "./BookingSettings";

export default class Settings extends Component {

    state = {
        user: {}
    };

    render() {
        if (JSON.parse(localStorage.getItem("user").admin === false)) {
            return (<Redirect to={"/"}/>)
        } else {
            return (
                <div style={{margin: 15}}>
                    {JSON.parse(localStorage.getItem("user")).admin ?
                        <div className={"columns"}>
                            <div className={"column is-2"}>
                                <Menu/>
                            </div>
                            <div className={"column is-10"}>
                                <Route exact path={"/settings/users"} component={Users}/>
                                <Route exact path={"/settings/bookingSettings"} component={BookingSettings}/>
                                <Route exact path={"/settings/uniteamSettings"} component={UniteamSettings}/>
                                <Route exact path={"/settings/leadTimes"} component={LeadTimes}/>
                            </div>
                        </div>
                        : null}
                </div>
            );
        }
    }
}