import React, {Component} from 'react';
import CreateUser from "./CreateUser";
import Spinner from "../utils/Spinner";
import {dev, devIp, ip} from "../../package.json";

export default class Users extends Component {

    state = {
        users: [],
        ip: dev ? devIp : ip
    };

    componentDidMount() {
this.fetchUsers();
    }

    fetchUsers = () => {
        fetch(this.state.ip + "users")
            .then((response) => {
                return response.json();
            })
            .then((users) => {
                this.setState({users});
            });
    }

    updateUser = e => {
        let user = {...this.state.users[e.target.id]};

        fetch(this.state.ip + "updateUser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        })
            .then(() => {
                console.log("Updated user: " + user.username);
            })
    };

    deleteUser = e => {

        fetch(this.state.ip + "deleteUser?username=" + this.state.users[e.target.id].username)
            .then(() => {
                console.log("Deleted user");
                this.fetchUsers();
            });
    };

    handlePasswordChange = e => {
        console.log("resetting password");
        console.log(this.state.ip + "resetPassword")
        let user = this.state.users[e.target.id];
        fetch(this.state.ip + "resetPassword", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        })
            .then(() => {
                console.log("Reset password mail sent!")
            })
    }

    renderUsers = () => {
        let users = [...this.state.users];
        let sortedUsers = users.sort((a, b) => {
            if(a.username < b.username) return -1;
            if(b.username > b.username) return 1;
            return 0;
        })

        return sortedUsers.map((user, index) => {
            if(user.company !== 66 && user.company !== 44 && user.company !== 11) {
                user.company = "";
            }
            if(user.customer === 0) {
                user.customer = "";
            }

                return (
                    <tr key={index}>
                        <td>{user.username}</td>
                        <td><input className={"input"} type="text" value={user.customer} name={"customer"}
                                   onChange={this.handleChange} id={index}/></td>
                        <td><input className={"input"} type="text" value={user.company} name={"company"}
                                   onChange={this.handleChange} id={index}/></td>
                        <td><input className={"checkbox"} type="Checkbox" checked={user.admin} name={"admin"}
                                   onChange={this.handleCheckbox} id={index}/></td>
                        <td><input className={"checkbox"} type="Checkbox" checked={user.simple} name={"simple"}
                                   onChange={this.handleCheckbox} id={index}/></td>
                        <td>
                            <button className={"button"}
                                    onClick={this.handlePasswordChange} id={index}>Send reset password mail
                            </button>
                        </td>
                        <td>
                            <button className={"button is-success"} onClick={this.updateUser} id={index}>Save</button>
                        </td>
                        <td>
                            <button className={"button is-danger"} onClick={this.deleteUser} id={index}>Delete user</button>
                        </td>
                    </tr>
                )
            }
        )
    };

    handleCheckbox = e => {
        let users = [...this.state.users];
        users[e.target.id][e.target.name] = e.target.checked;
        this.setState({users});
    };

    handleChange = e => {
        let users = [...this.state.users];
        if ((e.target.name === "customer" || e.target.name === "company") && e.target.value.length > 0) {
            users[e.target.id][e.target.name] = Number(e.target.value);
        } else {
            users[e.target.id][e.target.name] = e.target.value;
        }
        this.setState({users});
    };

    render() {
        if (this.state.users.length === 0) {
            return (
                <div>
                    <p>Loading</p>
                    <Spinner/>
                </div>
            )
        } else return (
            <div>
                <h1 className={"is-size-3"}>Users</h1>
                <table className={"table is-striped is-bordered"}>
                    <thead>
                    <tr>
                        <th>Username</th>
                        <th>Uniteam customer no</th>
                        <th>Company</th>
                        <th>Admin</th>
                        <th>Simple</th>
                        <th>Send</th>
                        <th>Save</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderUsers()}
                    </tbody>
                </table>
                <CreateUser fetchUsers={this.fetchUsers}/>
            </div>
        );
    }
}