import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import logo from "../images/jumbotransparent.png"
import {dev, devIp, ip} from "../../package.json";

export default class Navbar extends Component {

    state = {
        userMenuOpen: "",
        modalOpen: false,
        user: {},
        ip: dev ? devIp : ip
    };

    signOut = () => {
        localStorage.clear();
        window.location.replace("/")
    };

    render() {
        return (
            <nav className="navbar is-info" role="navigation" aria-label="main navigation">
                <div className="navbar-menu navbar-brand">
                    <NavLink className="navbar-item" to={"/"}>
                        <img src={logo} alt="PO System"/>
                    </NavLink>
                </div>
                <div className="navbar-end">
                    <div onClick={() => {
                        this.setState({userMenuOpen: this.state.userMenuOpen === "is-active" ? "" : "is-active"})
                    }} className={"navbar-item has-dropdown " + this.state.userMenuOpen}>
                        <div className="navbar-link">
                            {JSON.parse(localStorage.getItem("user")).username}
                        </div>
                        <div className="navbar-dropdown is-right">
                            <NavLink to={"/"} className="navbar-item">
                                Home
                            </NavLink>
                            {JSON.parse(localStorage.getItem("user")).admin ?
                                <React.Fragment>
                                    <NavLink to={"/help"} className="navbar-item">
                                        Help
                                    </NavLink>
                                    <NavLink to={"/settings"} className="navbar-item">
                                        <div style={{justifyContent: "right"}}>
                                            < span> Settings </span>
                                            <span style={{justifyContent: "right"}}>
                                        <i className="fas fa-cog"/>
                                        </span>
                                        </div>
                                    </NavLink>
                                </React.Fragment>
                                : null}
                            <hr className="navbar-divider"/>
                            <div style={{cursor: "pointer"}} onClick={this.signOut} className="navbar-item">
                                Sign out
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}