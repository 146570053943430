import React, {Component} from "react";
import Customer from "./Customer";
import {dev, devIp, ip} from "../../package.json";

export default class UniteamSettings extends Component {

    state = {
        settings: {},
        customers: [],
        ip: dev ? devIp : ip
    };

    componentDidMount() {

        fetch(this.state.ip + "customers")
            .then((response) => {
                return response.json();
            })
            .then((customers) => {
                this.setState({customers});
            });
    }

    renderCustomer = () => {
        return this.state.customers.map((customer, index) => {
            return <Customer key={customer.uid} customer={customer} id={index}/>
        })
    };

    save = () => {

        //todo: Check for mongo Update
        fetch(this.state.ip + "saveCustomers", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.customers)
        })
            .then(() => {
                console.log("Created customers");
            })

    };

    render() {
        return (
            <div>
                {this.renderCustomer()}
                {/*<button className={"button is-success"} style={{padding: 15, marginLeft: 5}} onClick={this.save}>Save</button>*/}
                <button className={"button"}
                        onClick={() => this.setState({leadTime: this.state.customers.push({})})}>Add new customer
                </button>
            </div>
        )
    }
}