import React, {Component} from 'react';
import Chart from "chart.js";

export default class Bar extends Component {

    state = {
        chart: {},
    };

    componentDidMount() {
        const node = this.node;
        let chart = new Chart(node, {
            type: 'bar',
            data: {
                labels: [],
                datasets: [{
                    label: null,
                    data: [1, 2, 3],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255,99,132,1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                onClick: this.handleClick,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
        this.setState({chart})
    }

    handleClick = e => {

        let obj = {
            target: {
                name: "country", value: Object.keys(this.props.data)[this.state.chart.getElementsAtEvent(e)[0]._index]
            }
        };
        this.props.handleChange(obj);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        let that = this;
        that.state.chart.data.labels = Object.keys(nextProps.data);
        that.state.chart.data.datasets[0].data = Object.keys(nextProps.data).map(e => nextProps.data[e]);
        this.state.chart.update();
    }

    render() {
        return (
            <canvas ref={node => (this.node = node)}/>
        );
    }
}
