import React, {Component} from "react";
import front from "../resources/Front1.PNG"
import shipper from "../resources/Shipper.PNG"
import depCtr from "../resources/depCtr.PNG"
import consignee from "../resources/Consignee.PNG"
import arrCtr from "../resources/arrCtr.PNG"
import depDate from "../resources/depDAte.PNG"
import actDepDate from "../resources/actDepDate.PNG"
import arrDate from "../resources/arrDate.PNG"
import deviation from "../resources/deviation.PNG"
import express from "../resources/express.PNG"

export default class Help extends Component {

    state = {
        message: "Hello world!",
    };

    render() {
        return (
            <div className={"columns"}>
                <div className={"column is-2"}/>
                <div className={"column is-8"}>
                    <div style={{
                        background: "white",
                        opacity: "0.8",
                        height: "100%",
                        width: "100%",
                        borderRadius: "3px",
                        boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)",
                        padding: 15,
                        margin: 15
                    }}>
                        <img src={front} alt="Front"/>
                        <ul>
                            <hr/>
                            <li>
                                <h1 className={"is-size-4"}>Departure </h1>
                                <p>Hentes fra shipper Name</p>
                                <img src={shipper} alt="shipper"/>
                            </li>
                            <li>
                                <h1 className={"is-size-4"}>Departure ctr </h1>
                                <p>Hentes fra shipper Zipcode + Country</p>
                                <img src={depCtr} alt="depCtr"/>
                            </li>
                            <hr/>
                            <li>
                                <h1 className={"is-size-4"}>Arrival place </h1>
                                <p>Hentes fra consignee name</p>
                                <img src={consignee} alt="shipper"/>
                            </li>
                            <hr/>
                            <li>
                                <h1 className={"is-size-4"}>Arrival Country</h1>
                                <p>Hentes fra consignee Zipcode + Country</p>
                                <img src={arrCtr} alt="shipper"/>
                            </li>
                            <hr/>
                            <li>
                                <h1 className={"is-size-4"}>Dep date</h1>
                                <p>Departure date felt i uniteam:</p>
                                <img src={depDate} alt="depDate"/>
                            </li>
                            <hr/>
                            <li>
                                <h1 className={"is-size-4"}>Act dep date</h1>
                                <p>Opret et event 991 eller 910 under events:</p>
                                <img src={actDepDate} alt="actDepDate"/>
                            </li>
                            <hr/>
                            <li>
                                <h1 className={"is-size-4"}>Arr. date</h1>
                                <p>Opret et event 992 eller 995 under events:</p>
                                <img src={arrDate} alt="arrDate"/>
                            </li>
                            <hr/>
                            <li>
                                <h1 className={"is-size-4"}>Deviations</h1>
                                <p>Tager event ID + Remark og viser data herfra</p>
                                <br/>
                                <p>Event 901: Cancelled</p>
                                <p>Event 997: Jumbo deviation</p>
                                <p>Event 998: Customer deviation</p>
                                <br/>
                                <img src={deviation} alt="deviation"/>
                                <hr/>
                                <p className={"is-size-4"}>Express</p>
                                <p>Udfyld Activity med rette XP kode for at lave en express levering</p>
                                <img src={express} alt="express"/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}