import React, {Component} from 'react';
import {dev, devIp, ip} from "../../package.json";

export default class Filters extends Component {

    state = {
        customers: [],
        ip: dev ? devIp : ip
    };

    componentWillMount() {
        this.fetchCustomers();
    }

    fetchCustomers = () => {
        fetch(this.state.ip + "customers")
            .then((response) => {
                return response.json();
            })
            .then((customers) => {
                this.setState({customers})
            });
    };

    renderCustomers = () => {
        return this.state.customers.map(customer => {
            return (
                <option key={customer.customerNo} value={customer.customerNo}>{customer.name}</option>
            )
        })
    };

    reset = () => {
        document.getElementById("endEta").value = '';
        document.getElementById("startEta").value = '';
        this.setState({customers: []})
        this.props.reset();
        this.fetchCustomers();
        this.renderCustomers();
    }

    render() {
        return (
            <div>
                <div className={"columns"}>
                    <div className={"column is-2"}>
                        <React.Fragment>
                            <p>Customer</p>
                            <div className={"select"}>
                                <select disabled={JSON.parse(localStorage.getItem("user")).admin !== true}
                                        onChange={this.props.handleChange} className={"select"} name={"customer"}>
                                    <option value={0}>All</option>
                                    {this.renderCustomers()}
                                </select>
                            </div>
                        </React.Fragment>
                    </div>
                    <div className={"column is-1"}>
                        <Select title={"Imp/Exp"} data={["I", "E"]} value={this.props.impExp} name={"impExp"} onChange={this.props.handleChange}/>
                    </div>
                    <div className={"column is-1"}>
                        <p>Status</p>
                        <div className={"select"}>
                            <select onChange={this.props.handleChange} value={this.props.status} className={"select"}
                                    name={"status"}>
                                <option value="All">All</option>
                                <option value="Open">In Transit</option>
                                <option value="Closed">Closed</option>
                                <option value="Late">Late</option>
                            </select>
                        </div>
                    </div>
                    <div className={"column is-1"}>
                        <Select title={"Dep Country"} data={this.props.aCountryList} value={this.props.aCountry}
                                name={"aCountry"} onChange={this.props.handleChange}/>
                    </div>
                    <div className={"column is-1"}>
                        <Select title={"Arr Country"} data={this.props.countryList} value={this.props.country}
                                name={"country"} onChange={this.props.handleChange}/>
                    </div>
                    <div className={"column is-2"}>
                        <label>Reset</label>
                        <br/>
                        <span className="button is-danger is-outlined" onClick={this.reset}>
                            <span>Reset filters</span>
                            <span className="icon is-small">
                                <i className="fas fa-times"/>
                            </span>
                        </span>
                    </div>
                    <div className={"column is-2"}>
                        <label>From(departure date)</label>
                        <input className={"input"} name={"startEta"} onChange={this.props.handleDate} type="date" id={"startEta"}/>
                    </div>
                    <div className={"column is-2"}>
                        <label>To(departure date)</label>
                        <input className={"input"} name={"endEta"} onChange={this.props.handleDate} type="date" id={"endEta"}/>
                    </div>
                </div>
            </div>
        );
    }
}

const Select = props => {
    return (
        <React.Fragment>
            <p>{props.title}</p>
            <div className={"select"}>
                <select style={{opacity: "0.9"}} onChange={props.onChange} value={props.value} className={"select"}
                        name={props.name}>
                    <option value="All">All</option>
                    {props.data.map(country => {
                        return <option key={country} value={country}>{country}</option>
                    })}
                </select>
            </div>
        </React.Fragment>
    )
};