import React, {Component} from 'react';
import {dev, devIp, ip} from "../../package.json";
import trailer from "../resources/jumbo-001.jpg";
import logo from "../resources/logotransparent.png";

export default class resetUserPassword extends Component {

    state = {
        username: "",
        oldPassword: "",
        repeatPassword: "",
        newPassword: "",
        ip: dev ? devIp : ip
    };

    async componentWillMount() {
        await this.setState({username: JSON.parse(localStorage.getItem("user")).username});
    }

    resetPassword = () => {
        if (this.state.oldPassword === JSON.parse(localStorage.getItem("user")).password) {
            if (this.state.newPassword === this.state.repeatPassword) {
                let user = {};
                user.username = this.state.username;
                user.oldPassword = this.state.oldPassword;
                user.newPassword = this.state.newPassword;
                fetch(this.state.ip + "resetUserPassword", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(user)
                })
                    .then(() => {
                        console.log("Password has been reset")
                        localStorage.clear();
                        window.location.replace("/")
                    })
            } else {
                console.log("Passwords Doesn't Match!")
            }
        } else {
            console.log("Old password was wrong!")
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.resetPassword();
        }
    };

    render() {
        return (
            <div style={{width: "100%", height: "100%", background: ""}}>
                <div className={"columns "} style={{marginTop: "30vh", background: ""}}>
                    <div className={"column is-2"}/>
                    <div className={"column is-8 loginContainer"}>
                        <div className={"columns"}>
                            <div className={"column is-6"} style={{border: 1, borderStyle: ""}}>
                                <img src={trailer} style={{width: "100%", height: "auto"}} alt="trailer"/>
                            </div>
                            <div className={"column is-6"}>
                                <div className={"columns"}>
                                    <div className={"column is-2"}/>
                                    <div className={"column is-8"}>
                                        <img src={logo} alt="Jumbo Logo"/>
                                    </div>
                                    <div className={"column is-2"}/>
                                </div>
                                <InputP
                                    text={"Old Password"}
                                    type="password"
                                    className="input"
                                    placeholder={"Old Password"}
                                    value={this.state.oldPassword}
                                    name="oldPassword"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={this.handleChange}/>
                                <InputP
                                    text={"New Password"}
                                    type="password"
                                    className="input"
                                    placeholder={"New Password"}
                                    value={this.state.newPassword}
                                    name="newPassword"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={this.handleChange}/>
                                <InputP
                                    text={"Repeat Password"}
                                    type="password"
                                    className="input"
                                    placeholder={"Repeat Password"}
                                    value={this.state.repeatPassword}
                                    name="repeatPassword"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={this.handleChange}/>
                                <input className="button" type="submit" value={"Reset Password"}
                                       onClick={() => this.resetPassword()}/>
                                {this.state.errorMessage !== "" ?
                                    <div>
                                        <p style={{color: "black"}}> {this.state.errorMessage}</p>
                                    </div>
                                    : null}
                                <input type="hidden" name={this.state.redirect + ""}/>
                            </div>
                        </div>
                    </div>
                    <div className={"column is-2"}/>
                </div>
            </div>
        );
    }
}
const InputP = props => {
    return (
        <div className="field">
            <label className="label">{props.text}</label>
            <div className="control">
                <input className={props.type === "checkbox" ? null : "input"} onKeyPress={props.onKeyPress}
                       placeholder={props.placeholder} type={props.type} value={props.value}
                       onChange={props.onChange} name={props.name}/>
            </div>
        </div>
    )
};