import React, {Component} from 'react';
import trailer from "../resources/jumbo-001.jpg"
import logo from "../resources/logotransparent.png"
import {dev, devIp, ip,version} from "../../package.json";

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            redirect: false,
            error: false,
            errorMessage: "",
            ip: dev ? devIp : ip
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    login = () => {
        let user = {};
        user.username = this.state.username;
        user.password = this.state.password;
        fetch(this.state.ip + "login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        })
            .then((response) => {
                return response.json();

            })
            .then((user) => {
                if (user.success) {
                    localStorage.setItem("customer", JSON.stringify(user.customer));
                    localStorage.setItem("user", JSON.stringify(user.user));
                    this.props.setLoggedIn();
                } else {
                    this.setState({error: true, errorMessage: user.errorMessage})
                }
            });
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.login();
        }
    };

    render() {
        return (
            <div style={{width: "100%", height: "100%", background: ""}}>

                <div className={"columns "} style={{marginTop: "30vh", background: ""}}>
                    <div className={"column is-2"}/>
                    <div className={"column is-8 loginContainer"}>
                        <div className={"columns"}>
                            <div className={"column is-6"} style={{border: 1, borderStyle: ""}}>
                                <img src={trailer} style={{width: "100%", height: "auto"}} alt="trailer"/>
                                <p style={{fontSize: 8, color: "#6e6c6c", margin: -6}}>{"V." + version}</p>
                            </div>
                            <div className={"column is-6"}>
                                <div className={"columns"}>
                                    <div className={"column is-2"}/>
                                    <div className={"column is-8"}>
                                        <img src={logo} alt="Jumbo Logo"/>
                                    </div>
                                    <div className={"column is-2"}/>
                                </div>
                                <InputP
                                    text={"Email"}
                                    type="email"
                                    className="input"
                                    placeholder={"Email"}
                                    value={this.state.username}
                                    name="username"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={this.handleChange}/>
                                <InputP
                                    text={"Password"}
                                    type="password"
                                    className="input"
                                    placeholder={"Password"}
                                    value={this.state.password}
                                    name="password"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={this.handleChange}/>
                                <input className="button" type="submit" value={"Login"} onClick={() => this.login()}/>
                                {this.state.errorMessage !== "" ?
                                    <div>
                                        <p style={{color: "black"}}> {this.state.errorMessage}</p>
                                    </div>
                                    : null}
                                <input type="hidden" name={this.state.redirect + ""}/>

                            </div>
                        </div>
                    </div>
                    <div className={"column is-2"}/>
                </div>
            </div>
        );
    }
}

const InputP = props => {
    return (
        <div className="field">
            <label className="label">{props.text}</label>
            <div className="control">
                <input className={props.type === "checkbox" ? null : "input"} onKeyPress={props.onKeyPress}
                       placeholder={props.placeholder} type={props.type} value={props.value}
                       onChange={props.onChange} name={props.name}/>
            </div>
        </div>
    )
};
