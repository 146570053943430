import React, {Component} from "react"

export default class Test extends Component {

    componentWillMount() {
        navigator.bluetooth.requestDevice({
            acceptAllDevices: true,
            optionalServices: ['battery_service']
        })
            .then(device => {
                console.log(device)
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <div>hello</div>
        )
    }
}