import React, {Component} from "react";
import LeadTime from "./LeadTime";
import {dev, devIp, ip} from "../../package.json";

export default class LeadTimes extends Component {

    state = {
        leadTimes: [],
        default: 10,
        updateDefault: {},
        ip: dev ? devIp : ip
    };

    componentDidMount() {
        this.fetchLeadTimes();
        this.fetchDefaultLeadTime();
    }

    fetchLeadTimes = () => {
        fetch(this.state.ip + "leadTimes")
            .then((response) => {
                return response.json();
            }).then((leadTimes) => {
            this.setState({leadTimes});
        })
    }

    fetchDefaultLeadTime = () => {
        fetch(this.state.ip + "defaultLeadTime")
            .then((response) => {
                return response.json();
            })
            .then((leadTime) => {
                this.setState({default: leadTime.interval})
            })
    }

    renderLeadTimes = () => {
        return this.state.leadTimes.map((leadTime) => {
            return (
                <div key={leadTime.country}>
                    <LeadTime leadTime={leadTime.leadTimes} delete={this.removeLeadTime} add={this.add}
                              handleChange={this.handleLeadTime} country={leadTime.country}
                              handleCountry={this.handleCountry} deleteCountry={this.deleteCountry}/>
                    <hr/>
                </div>
            )
        })
    };

    add = (country) => {
        let leadTimes = [...this.state.leadTimes];
        leadTimes.forEach(time => {
            if (time.country === country) {
                if (time.leadTimes === undefined || time.leadTimes === null) {
                    time.leadTimes = [];
                    time.leadTimes.push({})
                } else {
                    time.leadTimes.push({});
                }
            }
        });
        this.setState({leadTimes});
    };

    handleCountry = (country) => {
        let leadTimes = [...this.state.leadTimes];
        let checkCountry;
        for(let i = 0; i < leadTimes.length; i++) {
            if(leadTimes[i].country.substring(0,2) === country["country"].substring(0,2)) {
                checkCountry = leadTimes[i].country;
                break;
            } else {
                checkCountry = country["country"].substring(0,country["country"].length-1)
            }
        }

        let leadTime = leadTimes.findIndex(leadTime => leadTime.country === checkCountry);
        leadTimes[leadTime].country = country["country"];
        this.setState({leadTimes})
    }

    handleLeadTime = (obj, country) => {
        let leadTimes = [...this.state.leadTimes];
        leadTimes.forEach(leadTime => {
            if (leadTime.country === country) {
                if (leadTime.leadTimes === undefined || leadTime.leadTimes === null) {
                    leadTime.leadTimes = [];
                } else {
                    leadTime.leadTimes[obj.id][obj.name] = Number(obj.value);
                }
            }
        });
        this.setState({leadTimes});
    };

    handleChange = e => {
        let obj = {country: "Default", [e.target.name]: e.target.value};
        this.setState({default: e.target.value})
        this.setState({updateDefault: obj}, () => {
            console.log(this.state)
        })
    };

    updateDefault = () => {
        fetch(this.state.ip + "updateDefaultLeadTime", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.updateDefault)
        })
            .then(() => {
                console.log("Updated default leadTime")
            })
    }

    addLeadTime = () => {
        let leadTime = {};
        leadTime.country = "";
        leadTime.leadTimes = [];
        leadTime.interval = 0;
        let leadTimes = [...this.state.leadTimes];
        leadTimes.push(leadTime)
        this.setState({leadTimes})
    }

    removeLeadTime = (id, country) => {
        let leadTimes = [...this.state.leadTimes];
        leadTimes.forEach(leadTime => {
            if (leadTime.country === country) {
                leadTime.leadTimes.splice(id, 1);
            }
        });
        this.setState({leadTimes});
    };

    deleteCountry = (e) => {
        console.log(this.state.leadTimes)
        let leadTimes = [...this.state.leadTimes]

        for(let i = 0; i < leadTimes.length; i++) {
            if(leadTimes[i].country === e) {
                let leadTime = leadTimes[i]
                fetch(this.state.ip + "deleteLeadTime", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(leadTime)
                })
                    .then(() => {
                        console.log("Leadtime Deleted!")
                        this.fetchLeadTimes();
                    })
            }
        }


    }

    save = () => {
        fetch(this.state.ip + "saveLeadTimes", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.leadTimes)
        })
            .then(() => {
                console.log("saved Leadtimes")
            })
    };

    render() {
        return (
            <div>
                <span className={"is-size-2"}>Lead times</span>
                {this.renderLeadTimes()}
                <button className={"button"} onClick={this.addLeadTime}>+</button>
                <button className={"button is-success"} style={{padding: 15, marginLeft: 5}} onClick={this.save}>Save
                </button>
                <br/>
                <span className={"is-size-2"}>Default interval</span>
                <p className={"is-size-6"}>(If country isn't in the table above)</p>
                <div className={"columns"}>
                    <div className={"column is-1"}>
                        <label>Interval</label>
                        <br/>
                        <input className={"input"} name="interval" onChange={this.handleChange} type="number"
                               value={this.state.default}/>
                    </div>
                </div>
                {<button className={"button is-success"} onClick={this.updateDefault}>Update</button>}
            </div>
        )
    }
}