import React, {Component} from 'react';
import {dev, devIp, ip} from "../../package.json";

export default class CreateUser extends Component {

    state = {
        username: "",
        password: "",
        checkPassword: "",
        admin: false,
        customer: undefined,
        company: "",
        ip: dev ? devIp : ip
    };

    createUser = () => {
        if (this.state.password === this.state.checkPassword) {
            let user = {};
            user.admin = this.state.admin;
            user.company = Number(this.state.company);
            user.customer = Number(this.state.customer);
            user.username = this.state.username;
            user.password = this.state.password;

            fetch(this.state.ip + "saveUser", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
                .then(() => {
                    console.log("User created!")
                    this.props.fetchUsers();
                })

        } else {
            console.error("passwords not equal")
        }
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    };

    handleCheck = e => {
        this.setState({[e.target.name]: e.target.checked});
    };

    render() {
        return (
            <div>
                <div className={"column is-6"}>
                    <h3 className={"is-size-3"}>Create user</h3>
                    <label>Email:</label>
                    <input className={"input"} value={this.state.username} onChange={this.handleChange}
                           name={"username"} type="email"/>
                    <label>Password:</label>
                    <input className={"input"} value={this.state.password} onChange={this.handleChange}
                           name={"password"} type="password"/>
                    <label>Repeat password:</label>
                    <input className={"input"} value={this.state.checkPassword} onChange={this.handleChange}
                           name={"checkPassword"} type="password"/>
                    <label>Uniteam customer no:</label>
                    <input className={"input"} value={this.state.customer} onChange={this.handleChange}
                           name={"customer"} type="text"/>
                    <label>Uniteam selskab:</label>
                    <input className={"input"} value={this.state.company} onChange={this.handleChange} name={"company"}
                           type="text"/>
                    <label>Admin:</label>
                    <input style={{margin: 5}} className={"checkbox"} onChange={this.handleCheck} name={"admin"}
                           type="checkbox"/>
                    <br/>
                    <button className={"button"} onClick={this.createUser}>Create user</button>
                </div>
            </div>
        );
    }
}