import React, {Component} from "react";
import {dev, devIp, ip} from "../../package.json";

export default class BookingSettings extends Component {

    state = {
        bookingNo: "",
        booking: {},
        message: "",
        customer: "",
        toDelete: "",
        ip: dev ? devIp : ip
    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    deleteBooking = () => {
        fetch(this.state.ip + "deleteBooking?bookingNo=" + this.state.toDelete)
            .then(() => {
                this.setState({message: "deleted " + this.state.toDelete})
            })
    };

    getBooking = () => {
        fetch(this.state.ip + "booking?bookingNo=" + this.state.bookingNo)
            .then((response) => {
                return response.json();
            })
            .then((booking) => {
                this.setState({booking})
            })
    };

    getBookings = () => {
        fetch(this.state.ip + "deleteBookings?customerNo=" + this.state.customer)
            .then(() => {
                console.log("deleted")
            })
    };

    render() {
        return (
            <div>
                {this.state.message}
                <br/>
                <label>Get booking:</label>
                <input className={"input"} placeholder={"book no e.x 612345"} type="number" value={this.state.bookingNo}
                       name={"bookingNo"} onChange={this.handleChange}/>
                <button className={"button"} onClick={this.getBooking}>Get</button>
                <br/>
                <textarea
                    hidden={this.state.bookingNo === "" ? this.state.booking : false}
                    disabled
                    rows={16}
                    cols={50}
                    value={JSON.stringify(this.state.booking, undefined, 2)}
                    name={"booking"}/>
                <br/>
                <label>Delete booking:</label>
                <input className={"input"} type="number" value={this.state.toDelete} name={"toDelete"}
                       onChange={this.handleChange}/>
                <button className={"button"} onClick={this.deleteBooking}>Delete</button>
                <br/>
                <label>Delete customers bookings:</label>
                <input className={"input"} placeholder={"customerNo"} type="number" value={this.state.customer}
                       name={"customer"} onChange={this.handleChange}/>
                <button className={"button"} onClick={this.getBookings}>Delete</button>
            </div>
        )
    }
}