import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

export default class Menu extends Component {

    state = {
        user: {}
    };

    render() {

        return (
            <aside className="menu" style={{padding: 10}}>
                <p className="menu-label">
                    General
                </p>
                <ul className="menu-list">
                    <React.Fragment>
                        <li><NavLink to={"/settings/bookingSettings"}>Booking settings</NavLink></li>
                        <li><NavLink to={"/settings/uniteamSettings"}>Customers</NavLink></li>
                        <li><NavLink to={"/settings/leadTimes"}>Lead times</NavLink></li>
                        <li><NavLink to={"/settings/users"}>Users</NavLink></li>
                    </React.Fragment>
                </ul>
            </aside>

        )
    }
}

