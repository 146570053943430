import React, {Component} from "react";

export default class LeadTime extends Component {

    handleChange = e => {
        this.props.handleChange({name: e.target.name, value: e.target.value, id: e.target.id}, this.props.country)
    };

    handleCountry = e => {
        this.props.handleCountry({country: e.target.value})
    }

    delete = (e) => {
        this.props.delete(e.target.id, this.props.country);
    };

    deleteCountry = (e) => {
        this.props.deleteCountry(e.target.id, this.props.country);
    }

    add = () => {
        this.props.add(this.props.country);
    };

    renderTimes = () => {
        if (this.props.leadTime !== undefined && this.props.leadTime !== null) {
            return this.props.leadTime.map((leadTime, index) =>
                <div key={index} style={{border: 1, color: "black", borderStyle: "dotted", padding: 10, margin: 10}}>
                    <div className="field">
                        <label className="label">From</label>
                        <div className="control">
                            <input className="input is-rounded" id={index} value={leadTime.from} name={"from"}
                                   onChange={this.handleChange} type="text" placeholder="Rounded input"/>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">To</label>
                        <div className="control">
                            <input className="input is-rounded" id={index} value={leadTime.to} name={"to"}
                                   onChange={this.handleChange} type="text" placeholder="Rounded input"/>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Days</label>
                        <div className="control">
                            <input className="input is-rounded" id={index} value={leadTime.interval} name={"interval"}
                                   onChange={this.handleChange} type="text" placeholder="Rounded input"/>
                        </div>
                    </div>
                    <button className={"button is-warning"} onClick={this.delete} id={index}>Remove</button>
                </div>
            )
        } else {
            return null;
        }
    };

    render() {
        return (
            <div className={"columns"}>
                <div className={"column is-2"}>
                    <label>Country</label>
                    <br/>
                    <input className={"input"} name="country" onChange={this.handleCountry} type="text"
                           value={this.props.country}/>
                </div>
                <div className={"column is-1"}>
                    <br/>
                    <button className={"button is-danger"} onClick={this.deleteCountry} id={this.props.country}>x</button>
                </div>
                {this.renderTimes()}
                <button className={"button is-success"} onClick={this.add}>+</button>
            </div>
        )
    }
}