import React, {useEffect, useState} from "react"
import {apiIp, dev, devApiIp} from "../../package.json";
import adobeIcon from "../images/adobeicon.png"

export default function POD(props) {

    const [pod, setPod] = useState({});
    const [url, setUrl] = useState('');
    // If development in package.json is set to true, connect to localhost
    if (url === '') setUrl(dev ? devApiIp : apiIp);

    useEffect(() => {
        fetchPod();
    }, [url])

    function fetchPod() {
        fetch(`${url}podV2/pod?companyID=11&bookingNo=${props.bookingNo}`).then(res => {
            if (res.status === 200) {
                res.json()
                    .then(data => {
                        if (data != null && data.value != null) {
                            setPod(data.value);
                        }
                    })
            }
        })
    }

    function downloadPDF() {
        fetch(`${url}podV2/file?filename=${pod.filename}`)
            .then(res => {
                if (res.status === 200) {
                    res.blob().then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = "pod.pdf";
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove();
                    })
                }
            })
    }

    if (pod.bookNo > 0) {
        return (
            <td>
                <img src={adobeIcon} alt={"adobe pdf"} style={{cursor: "pointer"}} onClick={() => downloadPDF()}/>
            </td>
        )
    } else {
        return <td/>;
    }
}